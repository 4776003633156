let firststep = '';
let laststep = '';
let answers = [];
const sconditions = [];
let progressbar = '';

let spinnerVisible = false;
function showProgress() {
  if (!spinnerVisible) {
    $('div#spinner').fadeIn('fast');
    spinnerVisible = true;
  }
}
function hideProgress() {
  if (spinnerVisible) {
    const spinner = $('div#spinner');
    spinner.stop();
    spinner.fadeOut('fast');
    spinnerVisible = false;
  }
}

$(document).ready(() => {
  if ($('.qa-group').length === 0) {
    return;
  }
  sc_steps();
  sc_answer();
  sc_prev_step();
});

function sc_steps() {
  const num = $('.qa-group')
    .map(function () {
      return $(this).data('question');
    })
    .get();
  firststep = Math.min.apply(Math, num);
  laststep = Math.max.apply(Math, num);
  $('.qa-steps p .firstno').text(firststep);
  $('.qa-steps p .finalno').text(laststep);
  progressbar = 20;
  $('.qa-steps p .progress').css('width', `${progressbar}%`);
  if (progressbar > 40) {
    $('.qa-steps').css('color', '#ffffff');
  } else {
    $('.qa-steps').css('color', '#6BA7BA');
  }
}

function sc_answer() {
  $('.qa-group a.answer').on('click', function () {
    const groupnumber = $(this).parent().data('question');
    const groupnumberid = $(this).parent().data('questionid');
    const selectedanswer = $(this).data('answer');
    answers.push({q: groupnumberid, a: selectedanswer});
    if (groupnumber === laststep) {
      $('.qa-steps').hide();
      $(this).parent().removeClass('active');
      $('.qa-group[data-answer = answer]').addClass('active');
      sc_answers(answers);
    } else {
      const next = groupnumber + 1;
      $('.qa-steps p .firstno').text(next);
      progressbar = progressbar += 20;
      $('.qa-steps p .progress').css('width', `${progressbar}%`);
      if (progressbar > 40) {
        $('.qa-steps').css('color', '#ffffff');
      } else {
        $('.qa-steps').css('color', '#6BA7BA');
      }
      $(this).parent().removeClass('active');
      $(`.qa-group[data-question = ${next}]`).addClass('active');
    }
    return false;
  });
}

function sc_prev_step() {
  $('.qa-group p.prev a').on('click', function () {
    const groupnumber = $(this).parent().parent().data('question');
    const groupnumberid = $(this).parent().parent().data('questionid');
    const prev = groupnumber - 1;
    const prevgroup = $(this)
      .parent()
      .parent()
      .parent()
      .find(`[data-question=${prev}]`);
    const prevgroupid = prevgroup.data('questionid');
    answers = jQuery.grep(answers, (value) => value.q != prevgroupid);
    $('.qa-steps p .firstno').text(prev);
    progressbar = progressbar -= 20;
    $('.qa-steps p .progress').css('width', `${progressbar}%`);
    if (progressbar > 40) {
      $('.qa-steps').css('color', '#ffffff');
    } else {
      $('.qa-steps').css('color', '#6BA7BA');
    }
    $(this).parent().parent().removeClass('active');
    $(`.qa-group[data-question = ${prev}]`).addClass('active');
    return false;
  });
}

function sc_answers(answers) {
  let conditionsAnswers = [];

  showProgress();
  $.getJSON('/conditions.json', (conditions) => {
    $.each(conditions, (i, field) => {
      $.each(field.canswers, (key, val) => {
        conditionsAnswers = val.values;
        const result = isEqual(answers, conditionsAnswers);
        if (result == true) {
          sconditions.push({n: field.cname, d: field.cdesc});
        }
      });
    });
    sc_display(sconditions);
  });
}

function sc_display(sconditions) {
  hideProgress();
  if (
    Object.keys(sconditions).length !== 0 &&
    sconditions.constructor !== Object
  ) {
    $.each(sconditions, (j, fields) => {
      const container = $('<div class="answer clearfix"></div>');
      const title = $('<h3></h3>').text(fields.n);
      const desc = `${fields.d}`;
      const link = $(
        '<a href="/conditions-treatments/" class="sc-more" title=""></a>',
      ).text('Read more about knee conditions >');
      $('.answers').append(container);
      $(container).append(title, desc, link);
    });
  } else {
    const container = $('<div class="answer clearfix"></div>');
    const title = $('<h3></h3>').text('No Diagnosis');
    const desc = $('<p></p>').text(
      'Based on your selected answers you fall outside of any obvious conditions and if the symptoms persist we would recommend you seek specialist advice. Please click here to view an index of various symptoms and conditions to help you further your research, alternative click to book a consultation or let one of our team advise how you can get your GP to refer you to us.',
    );
    const link = $(
      '<a href="/conditions-treatments/" class="sc-more" title=""></a>',
    ).text('Read more about knee conditions >');
    $('.answers').append(container);
    $(container).append(title, desc, link);
  }
  const disclaimer = $('<p class="disclaimer"></p>').text(
    'Disclaimer: The symptom checker is for information purposes only and provides only an approximate guide as to what may be wrong with your knee. It should not be used instead of seeking professional medical advice or diagnosis.',
  );
  $('.answers').append(disclaimer);
}

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
};

function escapeHtml(string) {
  return String(string).replace(/[&<>"'`=\/]/g, (s) => entityMap[s]);
}

var isEqual = function (value, other) {
  // Get the value type
  const type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  const valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  const compare = function (item1, item2) {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else if (item1 !== item2) return false;
    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};
