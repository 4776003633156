// @ts-check
import * as focusTrap from 'focus-trap';
import {gsap} from 'gsap';
import Ready from './lib/ready.js';

/**
 * @type {HTMLElement|null}
 */
const nav = document.querySelector('.nav');
/**
 * @type {HTMLElement|null}
 */
/**
 * @type {NodeListOf<HTMLElement>}
 */
const navItems = document.querySelectorAll('#menu-main-menu li');
const topLevelNavItems = document.querySelectorAll('#menu-main-menu > li');
const hamburger = document.querySelector('.hamburger');
const menuTrap = focusTrap.createFocusTrap('.nav');
/**
 * @type { boolean }
 */
let navVisible = false;

function init() {
  if (!nav) return;
  if (window.innerWidth < 768) {
    if (navVisible) return;
    gsap.set(nav, {maxHeight: '0'});
    gsap.set('.hamburger--close', {autoAlpha: 0});
    gsap.set('.hamburger--open', {autoAlpha: 1, x: 0});
  } else {
    document.body.classList.remove('nav-open');
    gsap.set(nav, {maxHeight: 'none', autoAlpha: 1});
    gsap.set(navItems, {y: '0', autoAlpha: 1});
    nav.style.removeProperty('transform');
  }
}

function toggleNav() {
  navVisible = !navVisible;
  if (navVisible) {
    // menuTrap.activate()
    document.body.classList.add('nav-open');
    gsap.set(navItems, {y: '-15px', autoAlpha: 0, overwrite: true});
    gsap.set(topLevelNavItems, {borderColor: 'transparent'});
    gsap.to(nav, {
      maxHeight: '100%',
      autoAlpha: 1,
      ease: 'power1',
      delay: 0,
      duration: 0.16,
      overwrite: 'auto',
    });
    gsap.to(navItems, {
      autoAlpha: 1,
      y: 0,
      stagger: 0.04,
      delay: 0.2,
      overwrite: 'auto',
      zIndex: 15,
    });
    gsap.to(topLevelNavItems, {
      borderColor: 'var(--border-colour)',
      delay: 0.2,
      stagger: 0.02,
    });
    gsap.to('.hamburger--close', {duration: 0.2, autoAlpha: 1});
    gsap.to('.hamburger--open', {duration: 0.2, autoAlpha: 0, x: 0});
    hamburger?.setAttribute('aria-expanded', 'true');
  } else {
    // menuTrap.deactivate()
    gsap.to(nav, {
      maxHeight: '0',
      ease: 'power1',
      delay: 0.2,
      duration: 0.16,
      onComplete: () => {
        document.body.classList.remove('nav-open');
        gsap.set(navItems, {y: '-15px', autoAlpha: 0, overwrite: 'auto'});
      },
    });
    gsap.to('.hamburger--close', {duration: 0.2, autoAlpha: 0});
    gsap.to('.hamburger--open', {duration: 0.2, autoAlpha: 1, x: 0});
    hamburger?.setAttribute('aria-expanded', 'false');
  }
}
function outsideClick(event, notelem) {
  let clickedOutsideEl = true;

  if (event.target == notelem || notelem.contains(event.target)) {
    clickedOutsideEl = false;
  }
  if (clickedOutsideEl) return true;
  return false;
}

Ready(() => {
  init();
  // Trap focus on the menu once it's revealed
  hamburger.addEventListener('click', toggleNav);
  window.addEventListener('resize', init);
});
