$(document).ready(function(){
    $('.slideshow').slick({
        adaptiveHeight: false,
        autoplaySpeed:8000,
        pauseOnHover: false,
        autoplay: true,
        arrows:false,
        dots:false,
        fade: true,
        speed:900,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        touchThreshold: 100,
        useTransform: false
    }); 
});