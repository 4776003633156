// $(window).scroll(function () {
//   if ($(this).scrollTop() > 40) {
//     $('.fixedheader').addClass('fixed');
//     $('.sitewrap').css('paddingTop', '154px');
//   } else {
//     $('.fixedheader').removeClass('fixed');
//     $('.sitewrap').css('paddingTop', '0px');
//   }
// });
$(document).ready(() => {
  searchBox();
  scrollpage();
});
function searchBox() {
  $('.searchbutton').click(function (e) {
    e.preventDefault();
    $('.searchbox').toggleClass('active');
    $('.searchfield').focus();
    $(this).toggleClass('selected');
  });
}
function scrollpage() {
  if (window.location.hash) {
    $('html,body').animate(
      {
        scrollTop: $(window.location.hash).offset().top - 200,
      },
      400,
    );

    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('#') + 1);
    $(`#accordiontext${id}`).prop('checked', true);
  }
}
document.addEventListener('DOMContentLoaded', (event) => {
  // Find all oembed elements
  const oembedElements = document.querySelectorAll('oembed[url]');

  oembedElements.forEach((element) => {
    const url = element.getAttribute('url');
    let videoId = null;
    let platform = null;
    if (!url) return;

    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      videoId = extractYouTubeId(url);
      platform = 'youtube';
    } else if (url.includes('vimeo.com')) {
      videoId = extractVimeoId(url);
      platform = 'vimeo';
    }

    let videoEl;
    if (videoId) {
      // Create the iframe element
      if (platform === 'youtube') {
        videoEl = document.createElement('lite-youtube');
        videoEl.setAttribute('videoid', videoId);
      } else if (platform === 'vimeo') {
        videoEl = document.createElement('lite-vimeo');
        videoEl.setAttribute('videoid', videoId);
      }
      videoEl.setAttribute('width', '560'); // Set width
      videoEl.setAttribute('height', '315'); // Set height

      // Replace the oembed element with the iframe
      if (element.parentNode) {
        element.parentNode.replaceChild(videoEl, element);
      }
    }
  });

  // Function to extract the YouTube video ID from various URL formats
  function extractYouTubeId(url) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
  }

  // Function to extract the Vimeo video ID from various URL formats
  function extractVimeoId(url) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\d+\/)?|video\/|)(\d+)(?:$|\/|\?)/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
  }
});
